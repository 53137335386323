<template>
  <v-container>
    <v-row>
      <v-col>
        <v-card flat height="48px"></v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-slide-y-transition appear>
          <span class="turquesa--text d-block display-2 font-weight-bold">{{tituloSeccion}}</span>
        </v-slide-y-transition>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="10" offset="1" lg="8" offset-lg="2">
        <v-slide-y-transition mode="out-in" appear>
          <h2 class="d-block" v-html="tituloDescripcion"></h2>
        </v-slide-y-transition>
      </v-col>
    </v-row>
    <v-row>
      <arrow-separator />
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: 'RowTituloSeccion',
  components: {
    ArrowSeparator: () => import('@/views/components/ArrowSeparator')
  } ,
  props: {
    tituloSeccion: {
      type: String,
      default: '',
      required: true
    },
    tituloDescripcion: {
      type: String,
      default: '',
      required: true
    }
  }
}
</script>

<style>

</style>